import {
  BackgroundImage,
  BackgroundImageProps,
  useComputedColorScheme,
} from "@mantine/core";

export interface ColorSchemeBackgroundImageProps
  extends Omit<BackgroundImageProps, "src">,
    React.PropsWithChildren {
  lightSrc: string;
  darkSrc: string;
}

export const ColorSchemeBackgroundImage: React.FC<
  ColorSchemeBackgroundImageProps
> = ({ lightSrc, darkSrc, children, ...rest }) => {
  const colorScheme = useComputedColorScheme("light");

  return (
    <>
      <BackgroundImage
        {...rest}
        src={lightSrc}
        style={colorScheme === "light" ? {} : { display: "none" }}
        data-testid="background-image-light"
      >
        {children}
      </BackgroundImage>
      <BackgroundImage
        {...rest}
        src={darkSrc}
        style={colorScheme === "dark" ? {} : { display: "none" }}
        data-testid="background-image-dark"
      >
        {children}
      </BackgroundImage>
    </>
  );
};
