import { useComputedColorScheme } from "@mantine/core";
import cx from "clsx";
import Image, { ImageProps, StaticImageData } from "next/image";

export interface ColorSchemeImageProps extends Omit<ImageProps, "src"> {
  lightSrc: StaticImageData | string;
  darkSrc: StaticImageData | string;
}

export const ColorSchemeImage: React.FC<ColorSchemeImageProps> = ({
  lightSrc,
  darkSrc,
  className,
  style,
  ...rest
}) => {
  const colorScheme = useComputedColorScheme("light");
  const isDark = colorScheme === "dark";
  const isLight = colorScheme === "light";

  // todo: Hydration error on dev server
  return (
    <>
      <Image
        src={lightSrc}
        className={cx(className)}
        loading="lazy"
        style={{ ...style, display: isDark ? "none" : undefined }}
        {...rest}
      />

      <Image
        src={darkSrc}
        className={cx(className)}
        style={{ ...style, display: isLight ? "none" : undefined }}
        loading="lazy"
        {...rest}
      />
    </>
  );
};
