import { AnchorHTMLAttributes, PropsWithChildren, forwardRef } from "react";
import { useRouter } from "next/router";
import Link, { LinkProps } from "next/link";
import { prependLocaleToPath } from "@functions/i18n/prepend-locale-to-path";

export interface LocalizedLinkProps
  extends PropsWithChildren<
      Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps>
    >,
    LinkProps {
  href: string;
}

export const LocalizedLink = forwardRef<HTMLAnchorElement, LocalizedLinkProps>(
  ({ children, href, ...rest }, ref) => {
    const router = useRouter();
    const newHref = prependLocaleToPath(href, router);

    return (
      <Link href={newHref} ref={ref} {...rest}>
        {children}
      </Link>
    );
  }
);
