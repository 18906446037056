import { Box, BoxProps, Container, ContainerProps } from "@mantine/core";
import { useComponents } from "hooks";
import { ImageProps, StaticImageData } from "next/image";
import { FC, PropsWithChildren } from "react";
import { ColorSchemeImage } from "../color-scheme-image";
import { Body, BodyProps } from "./body";
import { Title, TitleProps } from "./title";

export interface HeroProps
  extends PropsWithChildren,
    Pick<ImageProps, "alt">,
    BoxProps,
    Partial<Pick<ContainerProps, "size">> {
  src: {
    dark: StaticImageData;
    light: StaticImageData;
  };
}

export interface HeroFC extends FC<HeroProps> {
  Title: FC<TitleProps>;
  Body: FC<BodyProps>;
}

export const Hero: HeroFC = ({
  children,
  src: { dark, light },
  size = "md",
  alt,
  ...rest
}) => {
  const [HeroTitle] = useComponents(children, Title);
  const [HeroBody] = useComponents(children, Body);

  return (
    <Box {...rest} w="100%" pos="relative">
      <ColorSchemeImage
        darkSrc={dark}
        lightSrc={light}
        fill
        style={{
          objectFit: "cover",
          objectPosition: "center",
          zIndex: 0,
        }}
        alt={alt}
        loading={undefined}
        priority
      />
      <Container size={size} py="sm" pos="relative" style={{ zIndex: 1 }}>
        {HeroTitle}
        <Box my="sm">{HeroBody}</Box>
      </Container>
    </Box>
  );
};

Hero.Title = Title;
Hero.Body = Body;
