import { MantineColor } from "@mantine/core";

export type ChartColors = {
  dark: MantineColor[];
  light: MantineColor[];
};

export const chartColors: ChartColors = {
  dark: [
    "red.4",
    "indigo.5",
    "green.4",
    "grape.3",
    "teal.5",
    "red.2",
    "orange.5",
    "pink.5",
    "violet.3",
    "yellow.5",
    "cyan.4",
    "lime.5",
    "blue.3",
  ],
  light: [
    "red.4",
    "indigo.6",
    "lime.6",
    "grape.5",
    "cyan.6",
    "orange.5",
    "violet.7",
    "pink.8",
    "green.6",
    "yellow.6",
    "blue.7",
    "pink.5",
    "orange.8",
  ],
};
