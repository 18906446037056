import { ActionIcon, Flex, Input, Transition } from "@mantine/core";
import { IconSearch, IconX } from "@tabler/icons-react";
import { useUiStore } from "stores";
import { useFocusTrap } from "@mantine/hooks";
import { Controller, useForm } from "react-hook-form";
import { useLocalizedRouter } from "hooks";
import { KeyboardEventHandler, useEffect } from "react";
import { useTranslation } from "next-i18next";
import classes from "./search.module.css";

interface SearchForm {
  search: string;
}

export const Search = () => {
  const { t } = useTranslation(["common"]);

  const [isSearchOpen, toggleSearch] = useUiStore((state) => [
    state.isSearchOpen,
    state.toggleSearch,
  ]);

  const ref = useFocusTrap();
  const { push } = useLocalizedRouter();
  const { control, handleSubmit, reset } = useForm<SearchForm>({
    defaultValues: { search: "" },
  });

  const handleSearch = ({ search }: SearchForm) => {
    push({ pathname: "/search", query: { query: search, page: 1 } });
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === "Escape") {
      toggleSearch();
      reset();
    }
  };

  useEffect(() => {
    reset();
  }, [isSearchOpen, reset]);

  return (
    <Transition
      duration={400}
      mounted={isSearchOpen}
      transition="slide-left"
      timingFunction="ease-in-out"
    >
      {(styles) => (
        <Flex
          align="center"
          className={classes.container}
          gap="xl"
          justify="space-between"
          p={{ base: "sm", md: "xl" }}
          style={styles}
        >
          <IconSearch size={32} />
          <form
            className={classes.form}
            onSubmit={handleSubmit((data) => handleSearch(data))}
            ref={ref}
          >
            <Controller
              name="search"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  classNames={{ input: classes.input }}
                  onKeyDown={handleKeyDown}
                  placeholder={t("search.placeholder")}
                  aria-label={t("search.placeholder")}
                />
              )}
            />
          </form>
          <ActionIcon
            aria-label={t("search.close")}
            className={classes.close}
            onClick={toggleSearch}
            color="white"
          >
            <IconX size={32} />
          </ActionIcon>
        </Flex>
      )}
    </Transition>
  );
};
