import { useComponents } from "hooks";
import { ActionIcon, Flex, Transition } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "next-i18next";
import { Target, TargetProps } from "./target";
import { Item, ItemProps } from "./item";
import classes from "./menu.module.css";

export interface MenuProps extends FC<PropsWithChildren> {
  Target: FC<TargetProps>;
  Item: FC<ItemProps>;
}

export const Menu: MenuProps = ({ children }) => {
  const [opened, handlers] = useDisclosure(false);

  const [MenuTarget] = useComponents(children, Target);
  const MenuItems = useComponents(children, Item);

  const { t } = useTranslation(["common"]);

  return (
    <li>
      <Flex justify="space-between" align="center" className={classes.target}>
        {MenuTarget}
        <ActionIcon
          onClick={handlers.toggle}
          className={classes.button}
          size="xl"
          aria-label={t("drop-down")}
        >
          {opened ? <IconChevronUp /> : <IconChevronDown />}
        </ActionIcon>
      </Flex>
      <Transition mounted={opened} transition="scale-y" timingFunction="ease">
        {(styles) => (
          <ul className={classes.menu} style={styles}>
            {MenuItems.map((item) => (
              <li key={crypto.randomUUID()}>{item}</li>
            ))}
          </ul>
        )}
      </Transition>
    </li>
  );
};

Menu.Target = Target;
Menu.Item = Item;
