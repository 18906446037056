import { useRouter } from "next/router";

export const useLocalizedRouter = <T = any,>() => {
  const router = useRouter();

  type Url = Parameters<typeof router.push>[0];
  type RouterMethodParams = Parameters<typeof router.push>;

  const localizePath = (path: Url): Url => {
    const locale = router.query.locale ?? router.locale ?? "";
    const prependLocale = (p: string) => `/${locale}${p}`.replace("//", "/");

    if (typeof path === "string") {
      return prependLocale(path);
    }
    if (typeof path === "object" && path.pathname) {
      return {
        ...path,
        pathname: prependLocale(path.pathname),
      };
    }

    return path;
  };

  const localizedRouterMethod =
    (method: typeof router.push | typeof router.replace) =>
    async (
      url: RouterMethodParams[0],
      as?: RouterMethodParams[1],
      options?: RouterMethodParams[2]
    ) => {
      const localizedUrl = localizePath(url);
      const localizedAs = as ? localizePath(as) : undefined;
      return method(localizedUrl, localizedAs, options);
    };

  return {
    ...router,
    pathname: router.pathname.replace("/[locale]", "") || "/",
    push: localizedRouterMethod(router.push),
    query: router.query as T,
    replace: localizedRouterMethod(router.replace),
  };
};
