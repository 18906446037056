import axios from "axios";
import { CaseCounts, Filters } from "./case-counts.type";

const baseURL = `https://${process.env.API_DOMAIN_NAME}/v1/case-counts`;

export const caseCountsApi = axios.create({
  baseURL,
});

export const getCaseCountsStratified = async (
  filters: Partial<Filters>
): Promise<CaseCounts> =>
  (await caseCountsApi.get<CaseCounts>("/stratified", { params: filters }))
    .data;
