import { Diseases } from "enums";
import { CaseCounts, Filters } from "api";
import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

export type MenuPanel = "filters" | "downloads" | "help" | "";
export type LocationFilter = "national" | "other";
export type DemographicFilter = "stratified" | "custom";

export interface DataFilterState {
  activeConfidenceIntervals: number[];
  activeDemographicFilter: DemographicFilter;
  activeMenuPanel: MenuPanel;
  activeSortKey: string;
  customCaseCounts: CaseCounts | undefined;
  customFilters: Filters;
  hiddenData: string[];
  isTouched: boolean;
  showError: boolean;
  addConfidenceInterval: (index: number) => void;
  addCustomCaseCounts: (caseCounts: CaseCounts) => void;
  addHiddenData: (uid: string) => void;
  clearConfidenceIntervalsAndHiddenData: () => void;
  clearCustomCaseCounts: () => void;
  removeConfidenceInterval: (index: number) => void;
  removeHiddenData: (uid: string) => void;
  setActiveDemographicFilter: (demographicFilter: DemographicFilter) => void;
  setActiveMenuPanel: (panel: MenuPanel) => void;
  setActiveSortKey: (sortKey: string) => void;
  setCustomFilters: (filters: Filters) => void;
  setIsTouched: (isTouched: boolean) => void;
  setShowError: (showError: boolean) => void;
}

const defaultActiveFilters: Filters = {
  diseaseId: [Diseases.Incidence.toString()],
};

const defaultCustomCaseCounts: CaseCounts = {
  filters: defaultActiveFilters,
  strats: [],
  data: [],
};

export const useDataFilterStore = createWithEqualityFn<DataFilterState>(
  (set) => ({
    activeConfidenceIntervals: [],
    activeDemographicFilter: "stratified",
    activeMenuPanel: "",
    activeSortKey: "",
    customCaseCounts: defaultCustomCaseCounts,
    customFilters: {} as Filters,
    hiddenData: [],
    isTouched: false,
    showError: false,
    addConfidenceInterval: (index) => {
      set(({ activeConfidenceIntervals }) => ({
        activeConfidenceIntervals: !activeConfidenceIntervals.includes(index)
          ? [...activeConfidenceIntervals, index]
          : activeConfidenceIntervals,
      }));
    },
    addCustomCaseCounts: (caseCounts) => {
      set(({ customCaseCounts }) => ({
        customCaseCounts:
          JSON.stringify(customCaseCounts?.filters) !==
            JSON.stringify(caseCounts.filters) &&
          JSON.stringify(caseCounts.filters)
            ? ({
                filters: caseCounts.filters,
                strats: [...customCaseCounts!.strats, ...caseCounts.strats],
                data: [...customCaseCounts!.data, ...caseCounts.data],
              } as CaseCounts)
            : customCaseCounts,
      }));
    },
    addHiddenData: (uid) => {
      set(({ hiddenData }) => ({
        hiddenData: !hiddenData.includes(uid)
          ? [...hiddenData, uid]
          : hiddenData,
      }));
    },
    clearConfidenceIntervalsAndHiddenData: () => {
      set(() => ({
        activeConfidenceIntervals: [],
        hiddenData: [],
      }));
    },
    clearCustomCaseCounts: () => {
      set(() => ({
        customFilters: {} as Filters,
        customCaseCounts: defaultCustomCaseCounts,
      }));
    },
    removeConfidenceInterval: (index) => {
      set(({ activeConfidenceIntervals }) => ({
        activeConfidenceIntervals: activeConfidenceIntervals.includes(index)
          ? activeConfidenceIntervals.filter((i) => i !== index)
          : activeConfidenceIntervals,
      }));
    },
    removeHiddenData: (uid) => {
      set(({ hiddenData }) => ({
        hiddenData: hiddenData.includes(uid)
          ? hiddenData.filter((i) => i !== uid)
          : hiddenData,
      }));
    },
    setActiveDemographicFilter: (demographicFilter) =>
      set(() => ({ activeDemographicFilter: demographicFilter })),
    setActiveMenuPanel: (panel) =>
      set(({ activeMenuPanel }) => ({
        activeMenuPanel: activeMenuPanel === panel ? "" : panel,
      })),
    setActiveSortKey: (sortKey) => {
      set(() => ({ activeSortKey: sortKey }));
    },
    setCustomFilters: (customFilters) => {
      set(() => ({
        customFilters,
      }));
    },
    setIsTouched: (isTouched) => set(() => ({ isTouched })),
    setShowError: (showError) => set(() => ({ showError })),
  }),
  shallow
);
