import { postSubscribeEmail } from "@api/subscription";
import {
  Anchor,
  Box,
  Center,
  CopyButton,
  Loader,
  Modal,
  Stack,
  Text,
  TextInput,
  UnstyledButton,
  useComputedColorScheme,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { getFromColorScheme } from "@utils/get-from-color-scheme";
import { useTranslation } from "next-i18next";
import { createRef, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import classes from "./subscribe-modal.module.css";

export interface SubscribeModalProps {
  opened: boolean;
  onClose: () => void;
}

export const SubscribeModal = ({ opened, onClose }: SubscribeModalProps) => {
  const colorScheme = useComputedColorScheme("light");
  const { t } = useTranslation(["common"]);
  const [isRecaptchaMissing, setIsRecaptchaMissing] = useState(false);
  const [email, setEmail] = useState("");
  const recaptchaRef = createRef<ReCAPTCHA>();
  const [url, setUrl] = useState(process.env.NEXT_PUBLIC_BASE_URL!);

  useEffect(() => {
    setUrl(`${window.location.origin}${window.location.pathname}`);
  }, []);

  const {
    isError,
    isIdle,
    isLoading,
    isSuccess,
    mutate: subscribeEmail,
    reset,
  } = useMutation({
    mutationFn: (data: {
      emailStr: string;
      recaptchaResponse: string | null | undefined;
    }) => postSubscribeEmail(data.emailStr, data.recaptchaResponse),
    onSuccess: () => {
      setEmail("");
      recaptchaRef.current?.reset();
    },
  });

  const processReCaptcha = (code) => {
    if (code) {
      setIsRecaptchaMissing(false);
    }
  };

  const handleSubscribeSubmit = (e) => {
    e.preventDefault();

    // Check if recaptcha was passed
    const code = recaptchaRef.current?.getValue();
    if (code === "") {
      setIsRecaptchaMissing(true);
      return;
    }

    // send request
    // todo: specific error messages?
    subscribeEmail({ emailStr: email, recaptchaResponse: code });
  };

  const handleClose = () => {
    // reset some state
    setEmail("");
    reset();
    onClose();
  };

  const title = t("subscription-modal.title");
  const placeholder = t("subscription-modal.placeholder");
  const errorMsg = t("subscription-modal.error-message");
  const close = t("close");
  const checkbox = t("subscription-modal.checkbox");

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      title={title}
      closeButtonProps={{ "aria-label": close }}
    >
      <form onSubmit={handleSubscribeSubmit}>
        <Stack gap="lg">
          <Box>
            <Text>{t("subscription-modal.info")}</Text>
            <CopyButton value={`${url}?subscribe=true`} timeout={2000}>
              {({ copied, copy }) => (
                <Anchor
                  component="button"
                  c={copied ? "teal.7" : undefined}
                  onClick={(e) => {
                    e.preventDefault();
                    copy();
                  }}
                >
                  {copied
                    ? t("subscription-modal.link-copied")
                    : t("subscription-modal.copy-link")}
                </Anchor>
              )}
            </CopyButton>
          </Box>

          <TextInput
            classNames={{ error: classes.error, input: classes.input }}
            value={email}
            onChange={(e) => {
              reset();
              setEmail(e.target.value);
            }}
            description={isSuccess && "Successfully subscribed"}
            descriptionProps={{ c: "green.7" }}
            error={isError ? errorMsg : false}
            placeholder={placeholder}
            aria-label={placeholder}
            type="email"
            rightSectionWidth={100}
            rightSection={
              <Center
                h="100%"
                w="100%"
                bg={getFromColorScheme(colorScheme, "blue.7", "blue.4")}
                style={{
                  borderTopRightRadius: "0.25rem",
                  borderBottomRightRadius: "0.25rem",
                }}
              >
                {(isIdle || isError) && (
                  <UnstyledButton type="submit">
                    <Text c={getFromColorScheme(colorScheme, "white", "black")}>
                      {t("subscription-modal.subscribe")}
                    </Text>
                  </UnstyledButton>
                )}
                {isLoading && (
                  <Loader variant="oval" size="sm" color="blue.9" />
                )}
                {isSuccess && <IconCheck color="white" />}
              </Center>
            }
          />

          <Box style={{ alignSelf: "center" }}>
            {isRecaptchaMissing && (
              <Text c="red.4" fw="bold" size="sm" mb="xs">
                {t("subscription-modal.recaptcha-required")}
              </Text>
            )}
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
              onChange={processReCaptcha}
              aria-label={checkbox}
            />
          </Box>
        </Stack>
      </form>
    </Modal>
  );
};
