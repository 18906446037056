import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "enums";
import { caseCountsApi } from "../axios";
import type { CaseCounts } from "../case-counts.type";

const getCaseCountsStratifiedMap = async (diseaseId: number, yearId: number) =>
  (
    await caseCountsApi.get<CaseCounts>("/stratified/jurisdictions", {
      params: {
        areaId: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
          38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 55, 134, 215,
          291, 298, 546, 608, 656, 731, 743, 779, 784, 820, 865, 869, 921, 1006,
          1031, 1185, 1203, 1249, 1311, 1352, 1549, 1556, 1765, 1839, 1857,
          1935, 1964, 2037, 2124, 2202, 2206, 2212, 2248, 2308, 2337, 2405,
          2432, 2501, 2553, 2570, 2571, 2572, 2580, 2664, 2789, 2834, 2894,
          3072,
        ],
        diseaseId: [diseaseId],
        yearId: [yearId],
      },
    })
  ).data;

export const useCaseCountsStratifiedMap = (
  diseaseId: number | undefined,
  yearId: number | undefined
) =>
  useQuery(
    [QueryKeys.CaseCountsStratified, diseaseId, yearId],
    () => getCaseCountsStratifiedMap(diseaseId!, yearId!),
    // Do not run this query unless we have the disease id and the year
    {
      enabled:
        !Number.isNaN(diseaseId) &&
        !Number.isNaN(yearId) &&
        diseaseId !== undefined &&
        yearId !== undefined,
    }
  );
