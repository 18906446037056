// todo: move to constants
export const STRATIFICATION_MAP = {
  age: "ageGroupDisplayName",
  area: "fullAreaDisplayName",
  disease: "diseaseDisplayName",
  gender: "genderDisplayName",
  race: "raceDisplayName",
  transmission: "transmissionDisplayName",
  sex: "sexDisplayName",
};
