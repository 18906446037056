import { DataType } from "@api/case-counts";
import {
  Loader,
  Table,
  Text,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { parseTableDataCell } from "functions/table";
import { getFromColorScheme } from "@utils/get-from-color-scheme";
import cx from "clsx";
import classes from "./datatable.module.css";

export type DatatableHeaderData<H extends Record<any, any> = {}> = {
  value: string;
  id: number | string;
  type?: DataType;
} & H;

type DatatableProps<H extends Record<any, any>> = {
  data: {
    headers: DatatableHeaderData<H>[];
    rows?: (number | string | undefined | null)[][];
    colors?: string[];
    isPercentage: boolean;
  };
  isLoading?: boolean;
  Header?: React.ComponentType<DatatableHeaderData<H>>;
  Cell?: React.ComponentType<any>;
  labelColumn?: boolean;
  align?: "right" | "left" | "center";
};

export const Datatable = <H extends Record<any, any>>({
  data: { headers, rows, colors, isPercentage },
  isLoading,
  Header,
  labelColumn = false,
  align,
}: DatatableProps<H>) => {
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme("light");

  const isEmpty =
    !rows ||
    rows.length === 0 ||
    rows.reduce<number>((count, row) => count + row.length, 0) === 0;

  // if header component is provided, use that
  const tableHeaders = (
    <Table.Tr>
      {headers.map((header) =>
        Header ? (
          <Header key={header.id} {...header} className={classes.header} />
        ) : (
          <Table.Th
            key={header.id}
            className={classes.header}
            style={{ color: "white", ...(align && { textAlign: align }) }}
          >
            {header.value}
          </Table.Th>
        )
      )}
    </Table.Tr>
  );

  // Create rows
  const tableRows = rows?.map((row, i) => (
    <Table.Tr key={labelColumn ? row[0] : crypto.randomUUID()}>
      {row.map((cell, j) => {
        const cellKey = `${row[0]}-${headers[j].id}`;
        const isPreliminary =
          headers[j].type === "preliminaryForQuarter" ||
          headers[j].type === "preliminaryForYear";

        // handle empty cell
        if (cell === undefined || cell === null)
          return (
            <Table.Td
              key={cellKey}
              style={{ ...(align && { textAlign: align }) }}
            >
              <Text className={classes.emptyCell} c="dimmed">
                ---
              </Text>
            </Table.Td>
          );

        // handle label cell
        if (labelColumn && j === 0) {
          // color gray if no data
          const isRowEmpty = !row.slice(1).some((v) => !!v);
          const emptyColor = getFromColorScheme(
            colorScheme,
            theme.colors.gray[2],
            theme.colors.gray[6]
          );

          return (
            <Table.Td
              key={cellKey}
              align={align ?? undefined}
              style={{
                boxShadow:
                  colors &&
                  `inset 8px 0 0 0 ${isRowEmpty ? emptyColor : colors[i]}`,
                whiteSpace: "pre-line",
              }}
            >
              <Text size="sm" className={classes.labelCell}>
                {cell}
              </Text>
            </Table.Td>
          );
        }

        // handle data cell
        return (
          <Table.Td
            key={cellKey}
            style={{ ...(align && { textAlign: align }) }}
          >
            <Text
              className={cx(classes.dataCell, {
                [classes.preliminaryCell]: isPreliminary,
              })}
            >
              {parseTableDataCell(cell, isPercentage)}{" "}
              {typeof cell === "string" && cell.includes("§") ? (
                <sup>§</sup>
              ) : (
                ""
              )}
            </Text>
          </Table.Td>
        );
      })}
    </Table.Tr>
  ));

  return (
    <Table
      striped={!isEmpty}
      highlightOnHover={!isEmpty}
      className={cx(classes.table, { [classes.labelColumn]: labelColumn })}
      withColumnBorders
      withTableBorder
      data-labelcol={labelColumn}
    >
      <Table.Thead className={classes.head}>
        {/*
          This is some draft aggregation code if we wanted
          to add aggregations to our data table
          <Table.Tr>
            {headers.map((h, i) => {
              if (i === 0) return <th>Average</th>;
              const colSum = rows
                ?.map((row) => row[i])
                .reduce<[number, number]>(
                  ([sum, count], val) => {
                    if (!val) return [sum, count];
                    const numeric = Number(val);

                    return [sum + numeric, count + 1];
                  },
                  [0, 0]
                );

              const colAverage = colSum && colSum[0] / colSum[1];

              return (
                <th>
                  <Text>{colAverage?.toLocalestring()}</Text>
                </th>
              );
            })}
          </Table.Tr>
        */}
        {tableHeaders}
      </Table.Thead>
      <Table.Tbody>
        {isLoading && (
          <Table.Tr>
            <Table.Td colSpan={headers.length} style={{ textAlign: "center" }}>
              <Loader />
            </Table.Td>
          </Table.Tr>
        )}
        {!isLoading && isEmpty && (
          <Table.Tr>
            <Table.Td colSpan={headers.length} style={{ textAlign: "center" }}>
              No data
            </Table.Td>
          </Table.Tr>
        )}
        {!isLoading && tableRows}
      </Table.Tbody>
    </Table>
  );
};
