import {
  Button,
  Checkbox,
  CopyButton,
  Flex,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import {
  IconBrandFacebook,
  IconBrandLinkedin,
  IconMail,
} from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import { ChangeEvent, useEffect, useState } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import xLogo from "@public/icons/x-logo.svg";
import Image from "next/image";
import classes from "./share-modal.module.css";

const shareLinks = [
  {
    name: "Facebook",
    icon: <IconBrandFacebook />,
    ShareButton: FacebookShareButton,
  },
  {
    name: "X",
    icon: (
      <Image
        src={xLogo}
        alt="test"
        height={16}
        width={16}
        style={{ margin: "0.25rem" }}
      />
    ),
    ShareButton: TwitterShareButton,
  },
  {
    name: "LinkedIn",
    icon: <IconBrandLinkedin />,
    ShareButton: LinkedinShareButton,
  },
  { name: "Email", icon: <IconMail />, ShareButton: EmailShareButton },
];

export interface ShareModalProps {
  opened: boolean;
  onClose: () => void;
}

export const ShareModal = ({ opened, onClose }: ShareModalProps) => {
  const { t } = useTranslation(["common"]);
  const [useFilters, setUseFilters] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(
      useFilters
        ? window.location.href
        : `${window.location.origin}${window.location.pathname}`
    );
  }, [useFilters]);

  const title = t("share-link");
  const shareCheckboxLabel = t("share-with-selected-filters");
  const close = t("close");

  const handleCheckboxClick = (e: ChangeEvent<HTMLInputElement>) => {
    setUseFilters(e.target.checked);
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={title}
      closeButtonProps={{ "aria-label": close }}
    >
      <Stack gap="lg">
        <Group grow>
          {shareLinks.map(({ ShareButton, name, icon }) => (
            <Stack gap="xs" align="center" key={name}>
              <ShareButton url={url}>
                <Flex className={classes.shareIcon} p="xs">
                  {icon}
                </Flex>
              </ShareButton>
              <Text size="sm">{name}</Text>
            </Stack>
          ))}
        </Group>
        <Flex className={classes.copyInput}>
          <TextInput
            classNames={{ input: classes.copyTextInput }}
            value={url}
            readOnly
            style={{ flexGrow: 1 }}
            aria-label={url}
          />
          <CopyButton value={url} timeout={2000}>
            {({ copied, copy }) => (
              <Button onClick={copy}>{copied ? "Copied" : "Copy"}</Button>
            )}
          </CopyButton>
        </Flex>
        <Checkbox
          checked={useFilters}
          onChange={handleCheckboxClick}
          radius={0}
          label={shareCheckboxLabel}
        />
      </Stack>
    </Modal>
  );
};
