import { Disease, useDiseases } from "@api/diseases";
import { Diseases } from "@enums/diseases";
import { DefaultMantineColor } from "@mantine/core";
import { useTranslation } from "next-i18next";
import { indicatorMap } from "./indicator-map";

export interface IndicatorData extends Disease {
  name: string;
  description: string;
  detailedDescription: string;
  goal: string;
  src: { light: string; dark: string };
  color: DefaultMantineColor;
  slug: string;
  titles: {
    cases: {
      name: string;
      title: string;
    };
    rate: {
      name: string;
      title: string;
    };
  };
  url: string;
}

export const useIndicatorData = () => {
  const { t } = useTranslation(["common"]);
  const { data, isLoading } = useDiseases();

  const indicatorData: IndicatorData[] =
    data
      ?.map<IndicatorData>((disease) => ({
        ...disease,
        ...indicatorMap[disease.id],
        name: disease.displayName,
        displayName: t(`indicators.${Diseases[disease.id]}.display-name`),
        description: t(`indicators.${Diseases[disease.id]}.description`),
        detailedDescription: t(
          `indicators.${Diseases[disease.id]}.detailed-description`
        ),
        goal: t(`indicators.${Diseases[disease.id]}.goal`),
        titles: {
          cases: {
            name: t(`indicators.${Diseases[disease.id]}.titles.cases.name`),
            title: t(`indicators.${Diseases[disease.id]}.titles.cases.title`),
          },
          rate: {
            name: t(`indicators.${Diseases[disease.id]}.titles.rate.name`),
            title: t(`indicators.${Diseases[disease.id]}.titles.rate.title`),
          },
        },
        url: t(`indicators.${Diseases[disease.id]}.url`),
      }))
      .sort((a, b) => indicatorMap[a.id].order - indicatorMap[b.id].order) ??
    [];

  return { indicatorData, isLoading };
};
