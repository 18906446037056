import {
  Title as MantineTitle,
  TitleProps as MantineTitleProps,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { getFromColorScheme } from "@utils/get-from-color-scheme";
import { PropsWithChildren } from "react";

export interface TitleProps extends PropsWithChildren, MantineTitleProps {}

export const Title: React.FC<TitleProps> = ({ children, ...rest }) => {
  const { colors } = useMantineTheme();
  const colorScheme = useComputedColorScheme("light");

  return (
    <MantineTitle
      order={1}
      c={getFromColorScheme(colorScheme, colors.teal[8], colors.yellow[6])}
      my="xs"
      {...rest}
    >
      {children}
    </MantineTitle>
  );
};

Title.displayName = "Title";
