import { Diseases } from "@enums/diseases";
import { RefObject } from "react";
import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

export interface UiState {
  activeIndicatorDetail: number;
  isSearchOpen: boolean;
  isSideNavOpen: boolean;
  toPngRef: RefObject<HTMLDivElement> | null;
  setToPngRef: (ref: RefObject<HTMLDivElement>) => void;
  toggleSearch: () => void;
  toggleSideNav: () => void;
  setActiveIndicatorDetail: (indicator: number) => void;
}

export const useUiStore = createWithEqualityFn<UiState>()(
  (set) => ({
    activeIndicatorDetail: Diseases.Incidence,
    isSearchOpen: false,
    isSideNavOpen: false,
    toPngRef: null,
    setToPngRef: (ref: RefObject<HTMLDivElement>) =>
      set(() => ({ toPngRef: ref })),
    toggleSearch: () =>
      set(({ isSearchOpen }) => ({ isSearchOpen: !isSearchOpen })),
    toggleSideNav: () =>
      set(({ isSideNavOpen }) => ({ isSideNavOpen: !isSideNavOpen })),
    setActiveIndicatorDetail: (indicator) =>
      set(() => ({
        activeIndicatorDetail: indicator,
      })),
  }),
  shallow
);
