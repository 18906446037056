import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

export interface DemographicTourState {
  isRunning: boolean;
  stepIndex: number;
  endTour: () => void;
  setIsRunning: (isRunning: boolean) => void;
  setStepIndex: (stepIndex: number) => void;
}

export const useDemographicTourStore =
  createWithEqualityFn<DemographicTourState>()(
    (set) => ({
      isRunning: false,
      stepIndex: 0,
      endTour: () => set(() => ({ isRunning: false, stepIndex: 0 })),
      setIsRunning: (isRunning) => set(() => ({ isRunning })),
      setStepIndex: (stepIndex) => set(() => ({ stepIndex })),
    }),
    shallow
  );
