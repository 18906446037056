import { Area } from "api";

export interface AreaMultiSelectNode {
  value: string;
  title: string;
  group: string;
  isState: boolean;
  abbreviation: string;
  stateDisplayName: string;
  children?: AreaMultiSelectNode[];
  selectable?: boolean;
  checkable?: boolean;
}
export interface AreaMultiSelectData {
  value: string;
  title: string;
  selectable: boolean;
  checkable: boolean;
  children: AreaMultiSelectNode[];
}

export const getAreasMultiSelectData = (areas: Area[]) => {
  const stateMap = new Map<string, string>();
  const values: AreaMultiSelectData[] = [
    {
      value: "Phase I EHE States",
      selectable: false,
      checkable: false,
      title: "Phase I EHE States",
      children: [],
    },
    {
      value: "Phase I EHE Counties & Areas",
      title: "Phase I EHE Counties & Areas",
      selectable: false,
      checkable: false,
      children: [],
    },
    {
      value: "Other States",
      title: "Other States",
      selectable: false,
      checkable: false,
      children: [],
    },
  ];

  areas.sort((a, b) => {
    // sort alphabetically by state name
    const valueComparison = a.stateDisplayName.localeCompare(
      b.stateDisplayName
    );
    if (valueComparison !== 0) {
      return valueComparison;
    }
    if (a.type === "State" && b.type !== "State") {
      return -1;
    }
    if (a.type !== "State" && b.type === "State") {
      return 1;
    }
    return 0;
  });

  // Map states to exclusive group
  areas.forEach((area) => {
    if (area.type === "State" && area.isEHE) {
      stateMap.set(area.abbreviation, "Phase I EHE States");
    }
    if (
      area.type === "County" &&
      area.isEHE &&
      stateMap.get(area.abbreviation) !== "State" // ? When would this be true??
    ) {
      stateMap.set(area.abbreviation, "Phase I EHE Counties & Areas");
    }
    if (
      stateMap.get(area.abbreviation) !== "Phase I EHE States" &&
      stateMap.get(area.abbreviation) !== "Phase I EHE Counties & Areas"
    ) {
      stateMap.set(area.abbreviation, "Other States");
    }
  });

  // first, push all state nodes
  areas
    .filter((area) => area.type === "State")
    .forEach((area) => {
      const group = stateMap.get(area.abbreviation)!;
      const groupNode = values.find((g) => g.value === group)!;

      const selectableStateNode = {
        value: area.id.toString(),
        title: area.stateDisplayName,
        group: stateMap.get(area.abbreviation)!,
        isState: true,
        abbreviation: area.abbreviation,
        stateDisplayName: area.stateDisplayName,
        children: [],
      };

      // For EHE states, push as different value and not selectable
      if (group === "Phase I EHE Counties & Areas") {
        const nonSelectableStateNode = {
          // This value will never be processed
          value: `PhaseICountiesState-${area.id.toString()}`,
          title: area.stateDisplayName,
          group: stateMap.get(area.abbreviation)!,
          isState: true,
          abbreviation: area.abbreviation,
          stateDisplayName: area.stateDisplayName,
          selectable: false,
          children: [],
        };
        groupNode.children.push(nonSelectableStateNode);

        // Also add state as selectable to Other States group
        if (area.stateDisplayName !== "District of Columbia") {
          values
            .find((g) => g.value === "Other States")!
            .children.push({
              value: area.id.toString(),
              title: area.stateDisplayName,
              group: "Other States",
              isState: true,
              abbreviation: area.abbreviation,
              stateDisplayName: area.stateDisplayName,
              children: [],
            });
        }
      } else {
        // Otherwise just add state as selectable to its group
        groupNode.children.push(selectableStateNode);
      }
    });

  // Then, place county nodes within states
  areas
    .filter((area) => area.type === "County" && area.isEHE)
    .forEach((area) => {
      const group = stateMap.get(area.abbreviation)!;
      const groupNode = values.find((g) => g.value === group);
      const stateNode = groupNode?.children.find(
        (value) => value.stateDisplayName === area.stateDisplayName
      )!;

      if (!stateNode.children) {
        stateNode.children = [];
      }

      stateNode.children.push({
        value: area.id.toString(),
        title:
          area.displayName === "" ? area.stateDisplayName : area.displayName,
        group: stateMap.get(area.abbreviation)!,
        isState: false,
        abbreviation: area.abbreviation,
        stateDisplayName: area.stateDisplayName,
      });
    });
  return values;
};
