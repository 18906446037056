import { ParsedUrlQuery, ParsedUrlQueryInput } from "querystring";
import { useEffect, useState } from "react";
import { useLocalizedRouter } from "..";

export type GeographicQueryParams = {
  location: string[] | undefined;
  type: string;
  tab: string | undefined;
};

export const useRouterQuery = <T extends ParsedUrlQuery>() => {
  const { query, replace, isReady } = useLocalizedRouter();
  const [isParamsReady, setIsParamsReady] = useState(false);

  // Need to wait for query params to load client side
  useEffect(() => {
    setIsParamsReady(isReady);
  }, [isReady]);

  // Lets also return a replace query function
  // todo: use T as type
  const replaceQuery = async (params: ParsedUrlQueryInput) => {
    const combinedParams: ParsedUrlQueryInput = { ...query, ...params };

    // filter out falsy params
    const truthyQueryParams = Object.fromEntries(
      Object.entries(combinedParams).filter(([_, value]) => Boolean(value))
    );

    await replace({ query: truthyQueryParams }, undefined, {
      shallow: true,
    });
  };

  return { query: query as T, isParamsReady, replaceQuery };
};
