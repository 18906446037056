import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "enums";
import { diseasesApi } from "../axios";

const getDiseaseLatestDataYear = async (diseaseId: number) =>
  (await diseasesApi.get<number>(`/${diseaseId}/latest-data-year`)).data;

export const useDiseaseLatestDataYear = (diseaseId: number) =>
  useQuery([QueryKeys.DiseaseLatestYear, diseaseId], () =>
    getDiseaseLatestDataYear(diseaseId)
  );
