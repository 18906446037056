import { useEffect } from "react";
import { useRouter } from "next/router";
import { appConfig } from "app.config";
import { languageDetector } from "@functions/i18n/language-detector";

export const useRedirect = (toProp) => {
  const router = useRouter();
  const to = toProp || router.asPath;

  useEffect(() => {
    const detectedLng = languageDetector.detect();
    if (to.startsWith(`/${detectedLng}`) && router.pathname === "/404") {
      // prevent endless loop
      router.replace(`/${detectedLng}${router.pathname}`);
      return;
    }

    // @ts-expect-error
    languageDetector.cache(detectedLng);
    if (
      (appConfig.APP_ENV === "production" && detectedLng !== "en") ||
      appConfig.APP_ENV !== "production"
    ) {
      router.replace(`/${detectedLng}${to}`);
    } else {
      window.location.href = "/";
    }
  });

  return null;
};
