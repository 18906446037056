import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "enums";
import { diseasesApi } from "../axios";
import { IndicatorYear } from "../disease.type";

export const getDiseaseYears = async (diseaseId: number) =>
  (await diseasesApi.get<IndicatorYear[]>(`/${diseaseId}/years`)).data;

export type DiseaseYearsQueryOptions<T> = Parameters<
  typeof useQuery<IndicatorYear[], unknown, T, (QueryKeys | number)[]>
>[2];

export const useDiseaseYears = <T = IndicatorYear[]>(
  diseaseId: number,
  options?: DiseaseYearsQueryOptions<T>
) =>
  useQuery(
    [QueryKeys.DiseaseYears, diseaseId],
    () => getDiseaseYears(diseaseId),
    options
  );
