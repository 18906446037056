import { RefObject } from "react";
import { create } from "zustand";

export interface GeographicPageState {
  toPngRef: RefObject<HTMLDivElement> | null;
  setToPngRef: (ref: RefObject<HTMLDivElement>) => void;
}

export const useGeographicPageStore = create<GeographicPageState>((set) => ({
  toPngRef: null,
  setToPngRef: (ref: RefObject<HTMLDivElement>) =>
    set(() => ({ toPngRef: ref })),
}));
