import { useCaseCountsStratified } from "@api/case-counts";
import { useJurisdictionsFilterStore } from "@stores/use-jurisdictions-filter-store";

export const useJurisdictionsPageData = () => {
  const activeLocation = useJurisdictionsFilterStore(
    (state) => state.activeLocation
  );

  const query = useCaseCountsStratified({
    areaId: activeLocation ? [activeLocation.id.toString()] : undefined,
    diseaseId: ["4", "280", "282", "283", "285", "287"],
  });

  return query;
};
