import { Area } from "@api/areas";
import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

export type Indicator =
  | "incidence"
  | "knowledge"
  | "diagnoses"
  | "linkage"
  | "viral"
  | "prep";

export interface JurisdictionsFilterState {
  activeIndicator: number | undefined;
  activeLocation: Area | undefined;
  setActiveIndicator: (indicator: number | undefined) => void;
  setActiveLocation: (location: Area | undefined) => void;
}

export const useJurisdictionsFilterStore =
  createWithEqualityFn<JurisdictionsFilterState>(
    (set) => ({
      activeIndicator: undefined,
      activeLocation: undefined,
      setActiveIndicator: (indicator: number | undefined) =>
        set(() => ({ activeIndicator: indicator })),
      setActiveLocation: (location: Area | undefined) =>
        set(() => ({ activeLocation: location })),
    }),
    shallow
  );
