import { Link } from "@components/ui";
import { ColorSchemeImage } from "@components/ui/color-scheme-image";
import { IndicatorData } from "@hooks/use-indicator-data";
import {
  Button,
  Flex,
  Stack,
  Text,
  Title,
  darken,
  getThemeColor,
  useComputedColorScheme,
} from "@mantine/core";
import { getFromColorScheme } from "@utils";
import cx from "clsx";
import { useTranslation } from "next-i18next";
import { SelectCard } from "../../../ui/select-card";
import classes from "./indicator-card.module.css";

export interface ColorProp {
  name: string;
  value: string;
}

type SomeIndicatorData = Partial<IndicatorData> &
  Pick<IndicatorData, "name" | "displayName" | "color" | "src" | "url">;

export interface IndicatorCardProps extends SomeIndicatorData {
  onSelect: () => void;
  expanded?: boolean;
  selected?: boolean;
  faded?: boolean;
}

export const IndicatorCard = ({
  name,
  displayName,
  detailedDescription,
  color,
  src,
  onSelect,
  faded,
  expanded,
  selected,
  url,
}: IndicatorCardProps) => {
  const { t } = useTranslation(["common", "data"]);
  const colorScheme = useComputedColorScheme("light");

  return (
    <>
      {expanded ? (
        <Flex
          pt="md"
          pl={{ base: "lg", xl: "1.75rem" }}
          pb="lg"
          gap="lg"
          className={classes.expandedCard}
          style={(theme) => ({
            border: `var(--mantine-radius-md) solid ${getThemeColor(
              color,
              theme
            )}`,
          })}
        >
          <ColorSchemeImage
            lightSrc={src.light}
            darkSrc={src.dark}
            alt={t(`common:indicators.alt-text`, {
              Indicator: name,
            })}
            className={classes.img}
            unoptimized
          />
          <Flex direction="column" miw="0">
            <Title order={2} pt=".5rem" pb="md" ta="left">
              {displayName}
            </Title>
            <Flex className={classes.descriptionAndButton}>
              <Text
                pb={{ base: "md", xs: "sm", md: "md" }}
                lh="117%"
                size="md"
                pr={{ base: "lg", xl: "2rem" }}
                ta="left"
              >
                {detailedDescription}
              </Text>
              <Button
                className={classes.button}
                component={Link}
                px="xs"
                size="md"
                href={url}
              >
                <Text className={classes.buttonText} inherit>
                  {t(`data:indicator-detail.more-about`, {
                    Indicator: displayName,
                  })}
                </Text>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <SelectCard
          selected={selected}
          onSelect={onSelect}
          p={0}
          w="100%"
          color={color}
          style={(theme) => ({
            color: getFromColorScheme(
              colorScheme,
              darken(getThemeColor(color, theme), 0.3),
              getThemeColor(color, theme)
            ),
          })}
          className={cx({ [classes.fadedCard]: faded })}
        >
          <Stack
            gap="md"
            py={{ base: "sm", xl: "lg" }}
            align="center"
            justify="space-between"
          >
            <ColorSchemeImage
              lightSrc={src.light}
              darkSrc={src.dark}
              alt={t(`common:indicators.alt-text`, {
                Indicator: name,
              })}
              className={classes.img}
              height={56}
              unoptimized
            />
            <Text
              className={classes.text}
              h={{ base: "1.75rem", md: "1.875rem", xl: "2rem" }}
              px={{ base: "lg", xl: "md" }}
              size="sm"
            >
              {displayName}
            </Text>
          </Stack>
        </SelectCard>
      )}
    </>
  );
};
