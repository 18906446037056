import { Layout } from "@components/layout";
import { Error, ErrorBoundary } from "@components/util";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import darkSrc from "@public/images/500-dark.svg";
import lightSrc from "@public/images/500-light.svg";
import resolver from "@styles/resolver";
import theme from "@styles/theme";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { appWithTranslation } from "next-i18next";
import { AppProps } from "next/app";
import Head from "next/head";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import NextNProgress from "nextjs-progressbar";

// Mantine core styles
import "@mantine/core/styles.layer.css";

// Mantine additional styles
import "@mantine/carousel/styles.layer.css";

// App specific styles
import "@styles/globals.css";

const tagManagerArgs = {
  gtmId: process.env.GTM_ID,
};

const App = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            cacheTime: 20 * (60 * 10000),
            staleTime: 15 * (60 * 10000),
          },
        },
      })
  );

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <>
      <Head>
        <title>AHEAD</title>
        <meta
          name="viewport"
          content="initial-scale=1, minimum-scale=1, width=device-width"
        />
      </Head>
      <MantineProvider
        theme={theme}
        cssVariablesResolver={resolver}
        defaultColorScheme="auto"
      >
        <Notifications position="top-right" />
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <Hydrate state={pageProps.dehydratedState}>
            <ErrorBoundary
              fallback={
                <Error
                  fullHeight
                  image={{ alt: "500", darkSrc, lightSrc }}
                  meta={{
                    title: "500 - Something Went Wrong | AHEAD",
                    description: "Looks like something went wrong.",
                  }}
                  title="Something Went Wrong"
                  text="Looks like something went wrong."
                />
              }
            >
              <NextNProgress
                color={theme.colors?.yellow?.[7] ?? undefined}
                options={{ showSpinner: false }}
                showOnShallow={false}
              />
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </ErrorBoundary>
          </Hydrate>
        </QueryClientProvider>
      </MantineProvider>
    </>
  );
};

export default appWithTranslation(App);
