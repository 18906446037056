import { Area, getAreas } from "@api/areas";
import { QueryKeys } from "@enums/query-keys";
import { useQuery } from "@tanstack/react-query";
import { toLowercaseDashed } from "@utils/to-lowercased-dashed";
import { useCallback } from "react";

export const useAreaBySlug = (slug: string[] | undefined) =>
  useQuery([QueryKeys.Areas], getAreas, {
    select: useCallback(
      (data: Area[]) =>
        data.find(
          ({ displayName, stateDisplayName }) =>
            toLowercaseDashed(stateDisplayName) === slug?.[0] &&
            (slug.length < 2 || toLowercaseDashed(displayName) === slug[1])
        ),
      [slug]
    ),
  });
