import { ColorSchemeSwitch } from "@components/layout/color-scheme-switch";
import { Search } from "@components/layout/header/search";
import { ColorSchemeImage, Link } from "@components/ui";
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Group,
  Menu,
  Text,
} from "@mantine/core";
import logoDark from "@public/images/ahead-logo-dark.svg";
import logoLight from "@public/images/ahead-logo-light.svg";
import { IconChevronDown, IconMenu2, IconSearch } from "@tabler/icons-react";
import cx from "clsx";
import { useTranslation } from "next-i18next";
import { Fragment } from "react";
import { useUiStore } from "stores";
import { useBreakpoint, useHeaderHeight, useLocalizedRouter } from "@hooks";
import classes from "./header.module.css";

export interface NavLinks {
  name: string;
  nested: {
    name: string;
    url: string;
    active: {
      url: string;
      isExact: boolean;
    };
  }[];
}

export const Header = () => {
  const { t } = useTranslation(["common"]);
  const { pathname } = useLocalizedRouter();

  const headerHeight = useHeaderHeight();
  const isBelowXs = useBreakpoint("xs");
  const isBelowMedium = useBreakpoint("md");

  const [toggleSearch, toggleSideNav] = useUiStore((state) => [
    state.toggleSearch,
    state.toggleSideNav,
  ]);

  const links = t("nav.links", { returnObjects: true }) as NavLinks[];

  // eslint-disable-next-line no-nested-ternary
  const getLogoHeight = () => (isBelowXs ? 40 : isBelowMedium ? 50 : 55);

  return (
    <Box
      className={classes.header}
      h={headerHeight}
      px={{ base: "md", md: "xl" }}
    >
      <Search />

      <Flex justify="space-between" align="center" h="100%">
        {isBelowMedium && !isBelowXs && (
          <Box style={{ flex: "1 1 33.33%" }}>
            <Button
              variant="filled"
              color="gray"
              leftSection={<IconMenu2 />}
              onClick={toggleSideNav}
              tt="uppercase"
            >
              {t("nav.buttons.menu")}
            </Button>
          </Box>
        )}

        {isBelowXs && (
          <Box style={{ flex: "1 1 33.33%" }}>
            <ActionIcon
              color="gray"
              onClick={toggleSideNav}
              aria-label={t("nav.buttons.menu")}
            >
              <IconMenu2 />
            </ActionIcon>
          </Box>
        )}

        <Box>
          <Link href="/">
            <ColorSchemeImage
              lightSrc={logoLight}
              darkSrc={logoDark}
              alt="AHEAD logo"
              height={getLogoHeight()}
              unoptimized
            />
          </Link>
        </Box>

        <Group gap="xs" justify="flex-end" style={{ flex: "1 1 33.33%" }}>
          {!isBelowMedium && (
            <Group gap="xs">
              <ul className={classes.list}>
                {Array.isArray(links) &&
                  links.map(({ name, nested }) => (
                    <li key={name}>
                      <Menu
                        // todo: dropdown shadow appears over target
                        classNames={{
                          divider: classes.dropdownDivider,
                          dropdown: classes.dropdown,
                          item: classes.dropdownItem,
                        }}
                        offset={0}
                        position="bottom-start"
                        trigger="hover"
                        transitionProps={{
                          duration: 300,
                          transition: "scale-y",
                        }}
                        zIndex={450}
                      >
                        <Menu.Target>
                          <Flex
                            align="center"
                            className={cx(classes.dropdownTarget, {
                              [classes.active]: nested.filter(
                                ({ active: { url, isExact } }) =>
                                  (isExact && pathname.endsWith(url)) ||
                                  (!isExact && pathname.includes(url))
                              ).length,
                            })}
                            direction="column"
                            justify="center"
                          >
                            <Text>{name}</Text>
                            <IconChevronDown size={16} />
                          </Flex>
                        </Menu.Target>
                        <Menu.Dropdown>
                          {nested.map(
                            (
                              {
                                name: nestedName,
                                url,
                                active: { url: activeUrl, isExact },
                              },
                              index
                            ) => (
                              <Fragment key={nestedName}>
                                <Link href={url} key={nestedName}>
                                  <Menu.Item
                                    className={cx({
                                      [classes.dropdownItemActive]:
                                        (isExact &&
                                          pathname.endsWith(activeUrl)) ||
                                        (!isExact &&
                                          pathname.includes(activeUrl)),
                                    })}
                                  >
                                    {nestedName}
                                  </Menu.Item>
                                </Link>
                                {index !== nested.length - 1 && (
                                  <Menu.Divider />
                                )}
                              </Fragment>
                            )
                          )}
                        </Menu.Dropdown>
                      </Menu>
                    </li>
                  ))}
              </ul>
              <ColorSchemeSwitch />
            </Group>
          )}

          <Flex className={classes.searchWrapper} align="center">
            <Text className={classes.searchLabel}>
              {t("nav.buttons.search")}
            </Text>
            <ActionIcon
              className={classes.searchButton}
              color="gray"
              variant="filled"
              radius="xl"
              aria-label={t("nav.buttons.search")}
              onClick={toggleSearch}
            >
              <IconSearch />
            </ActionIcon>
          </Flex>
        </Group>
      </Flex>
    </Box>
  );
};
