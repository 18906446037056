import { Anchor, Flex, Text } from "@mantine/core";
import classes from "./social-link.module.css";

export interface SocialLinkProps {
  text: string;
  href: string;
  icon: JSX.Element;
}

export const SocialLink: React.FC<SocialLinkProps> = ({ text, href, icon }) => (
  <Anchor href={href} className={classes.anchor} target="_blank">
    <Flex direction="column" justify="center" align="center">
      {icon}
      <Text size="sm">{text}</Text>
    </Flex>
  </Anchor>
);
