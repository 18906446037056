import { SocialLink } from "@components/layout/footer/social-link";
import { Anchor, Box, Container, Flex, Grid, Text, Title } from "@mantine/core";
import xLogo from "@public/icons/x-logo.svg";
import eheLogo from "@public/images/ehe-logo-footer.svg";
import mhafLogo from "@public/images/mhaf-logo.svg";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandYoutube,
  IconMail,
} from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import classes from "./footer.module.css";

const socialLinks = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/HIV.gov",
    icon: <IconBrandFacebook />,
  },
  {
    name: "X",
    url: "https://www.twitter.com/hivgov",
    icon: (
      <Image
        src={xLogo}
        alt="test"
        height={16}
        width={16}
        style={{ margin: "0.25rem" }}
      />
    ),
  },
  {
    name: "YouTube",
    url: "https://www.youtube.com/hivgov",
    icon: <IconBrandYoutube />,
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/hivgov",
    icon: <IconBrandInstagram />,
  },
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/showcase/office-of-infectious-disease-and-hiv-aids-policy",
    icon: <IconBrandLinkedin />,
  },
  {
    name: "Email",
    url: "https://cloud.connect.hhs.gov/HIV",
    icon: <IconMail />,
  },
];

const hivGovLinks = [
  {
    key: "footer.hivgov-mission-team",
    url: "https://www.hiv.gov/about-us/mission-and-team",
  },
  {
    key: "footer.locator-widgets",
    url: "https://www.hiv.gov/locator",
  },
  {
    key: "footer.blog-archives",
    url: "https://archive-it.org/collections/8315",
  },
  {
    key: "footer.contact-us",
    url: "https://www.hiv.gov/about-us/contact",
  },
  {
    key: "footer.privacy-policy",
    url: "https://www.hiv.gov/about-us/privacy-policy",
  },
  {
    key: "footer.disclaimers",
    url: "https://www.hiv.gov/about-us/disclaimer",
  },
  {
    key: "footer.nondiscrimination-notice",
    url: "https://www.hiv.gov/about-us/nondiscrimination",
  },
];

const hhsLinks = [
  {
    key: "footer.freedom-of-information-act",
    url: "https://www.hhs.gov/foia/index.html",
  },
  {
    key: "footer.hhs-gov",
    url: "https://www.hhs.gov",
  },
  {
    key: "footer.players-and-viewers",
    url: "https://www.hhs.gov/web/policies-and-standards/hhs-web-policies/plugins/index.html",
  },
  {
    key: "footer.hhs-508",
    url: "https://www.hhs.gov/web/section-508/index.html",
  },
  {
    key: "footer.hhs-privacy-policy",
    url: "https://www.hhs.gov/web/policies-and-standards/hhs-web-policies/privacy/index.html",
  },
  {
    key: "footer.white-house",
    url: "https://www.whitehouse.gov",
  },
  {
    key: "footer.usa-gov",
    url: "https://www.usa.gov",
  },
];

const languageLinks = [
  {
    language: "Español (Spanish)",
    url: "https://www.hhs.gov/ocr/get-help-in-other-languages/spanish.html",
  },
  {
    language: "繁體中文 (Chinese)",
    url: "https://www.hhs.gov/ocr/get-help-in-other-languages/traditional-chinese.html",
  },
  {
    language: "Tiếng Việt (Vietnamese)",
    url: "https://www.hhs.gov/ocr/get-help-in-other-languages/vietnamese.html",
  },
  {
    language: "한국어 (Korean)",
    url: "https://www.hhs.gov/ocr/get-help-in-other-languages/korean.html",
  },
  {
    language: "Tagalog",
    url: "https://www.hhs.gov/ocr/get-help-in-other-languages/tagalog.html",
  },
  {
    language: "Русский (Russian)",
    url: "https://www.hhs.gov/ocr/get-help-in-other-languages/russian.html",
  },
  {
    language: "العربية (Arabic)",
    url: "https://ocrportal.hhs.gov/ocr/cp/complaint_frontpage.jsf?lang=ar",
  },
  {
    language: "Kreyòl Ayisyen (Haitian Creole)",
    url: "https://www.hhs.gov/ocr/get-help-in-other-languages/haitian-creole.html",
  },
  {
    language: "Français (French)",
    url: "https://www.hhs.gov/ocr/get-help-in-other-languages/french.html",
  },
  {
    language: "Polski (Polish)",
    url: "https://www.hhs.gov/ocr/get-help-in-other-languages/polish.html",
  },
  {
    language: "Português (Portuguese)",
    url: "https://www.hhs.gov/ocr/get-help-in-other-languages/portuguese.html",
  },
  {
    language: "Italiano (Italian)",
    url: "https://ocrportal.hhs.gov/ocr/cp/complaint_frontpage.jsf?lang=it",
  },
  {
    language: "Deutsch (German)",
    url: "https://ocrportal.hhs.gov/ocr/cp/complaint_frontpage.jsf?lang=de",
  },
  {
    language: "فارسی (Farsi)",
    url: "https://ocrportal.hhs.gov/ocr/cp/complaint_frontpage.jsf?lang=fa",
  },
  {
    language: "日本語 (Japanese)",
    url: "https://ocrportal.hhs.gov/ocr/cp/complaint_frontpage.jsf?lang=ja",
  },
];

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box className={classes.footer}>
      <Container size="xl" my="xl">
        <Grid gutter="xl">
          <Grid.Col span={{ md: 8, sm: 12 }} className={classes.firstColumn}>
            <Title order={3}>{t("footer.connect-with-us")}</Title>
            <Grid mt="xs">
              {socialLinks.map(({ icon, name, url }) => (
                <Grid.Col key={name} span={{ base: 4, xs: "content" }}>
                  <SocialLink text={name} href={url} icon={icon} />
                </Grid.Col>
              ))}
            </Grid>
            <Title order={3} mt="xl" mb="xs" className={classes.title}>
              HIV.gov
            </Title>
            <Grid className={classes.linkGroup}>
              {hivGovLinks.map(({ key, url }) => (
                <Grid.Col key={key} span={{ base: 6, xs: "content" }}>
                  <Anchor href={url} target="_blank">
                    {t(key)}
                  </Anchor>
                </Grid.Col>
              ))}
            </Grid>
            <Title order={3} mt="xl" mb="xs" className={classes.title}>
              {t("footer.health-human-services")}
            </Title>
            <Grid className={classes.linkGroup}>
              {hhsLinks.map(({ key, url }) => (
                <Grid.Col key={key} span={{ base: 6, xs: "content" }}>
                  <Anchor href={url} target="_blank">
                    {t(key)}
                  </Anchor>
                </Grid.Col>
              ))}
            </Grid>
            <Title order={3} mt="xl" mb="xs" className={classes.title}>
              {t("footer.languages")}
            </Title>
            <Grid className={classes.linkGroup}>
              {languageLinks.map(({ language, url }) => (
                <Grid.Col key={language} span={{ base: 6, xs: "content" }}>
                  <Anchor href={url} target="_blank">
                    {language}
                  </Anchor>
                </Grid.Col>
              ))}
            </Grid>
          </Grid.Col>
          <Grid.Col span={{ md: 4, sm: 12 }} mt={{ sm: "xl" }}>
            <Grid ml={{ md: "lg" }} className={classes.secondColumnGroup}>
              <Grid.Col span={{ md: 12, xs: 6 }}>
                <Flex direction={{ md: "column", xs: "row" }} gap="md">
                  <Anchor
                    href="https://www.hiv.gov/federal-response/ending-the-hiv-epidemic/overview/"
                    target="_blank"
                  >
                    <Image
                      src={eheLogo}
                      alt="EHE logo"
                      width={94}
                      height={88}
                    />
                  </Anchor>
                  <Text size="sm">{t("footer.ehe-message")}</Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={{ md: 12, xs: 6 }} mt={{ md: "xl" }}>
                <Flex direction={{ md: "column", xs: "row" }} gap="md">
                  <Anchor
                    href="https://www.hiv.gov/federal-response/smaif/overview/"
                    target="_blank"
                  >
                    <Image
                      src={mhafLogo}
                      alt="MHAF logo"
                      width={146}
                      height={50}
                    />
                  </Anchor>
                  <Text size="sm">{t("footer.gov-site-message")}</Text>
                </Flex>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
        <Text size="sm" mt="xl">
          {t("footer.author-message")}{" "}
          <Anchor href="#" td="underline">
            Ending the HIV Epidemic.
          </Anchor>
        </Text>
      </Container>
    </Box>
  );
};
