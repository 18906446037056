import Head from "next/head";
import { FC } from "react";

export interface MetaProps {
  description: string;
  title: string;
}

export const Meta: FC<MetaProps> = ({ description, title }) => (
  <Head>
    <title>{title}</title>
    <meta name="description" content={description} />
  </Head>
);
