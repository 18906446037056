/* eslint-disable @next/next/no-html-link-for-pages */
import { ColorSchemeImage } from "@components/ui";
import { Stack, Text, Title } from "@mantine/core";
import { Meta } from "@components/util";
import { FC } from "react";
import { useHeaderHeight } from "@hooks";
import classes from "./error.module.css";

export interface ErrorProps {
  fullHeight?: boolean;
  image: {
    alt: string;
    lightSrc: string;
    darkSrc: string;
  };
  meta: {
    description: string;
    title: string;
  };
  text: string;
  title: string;
}

export const Error: FC<ErrorProps> = ({
  fullHeight,
  image: { alt, lightSrc, darkSrc },
  meta,
  text,
  title,
}) => {
  const headerHeight = useHeaderHeight();

  return (
    <Stack
      h={fullHeight ? "100vh" : `calc(100vh - 3rem - ${headerHeight}px)`}
      justify="center"
      align="center"
      className={classes.container}
    >
      <Meta title={meta.title} description={meta.description} />
      <ColorSchemeImage
        lightSrc={lightSrc}
        darkSrc={darkSrc}
        alt={alt}
        unoptimized
      />
      <Title ta="center">{title}</Title>
      <Text ta="center">
        {text} <a href="/">Back to home.</a>
      </Text>
    </Stack>
  );
};
