/* eslint-disable no-use-before-define */
import {
  BoxProps,
  ElementProps,
  Factory,
  Paper,
  StylesApiProps,
  createVarsResolver,
  factory,
  useProps,
  useStyles,
} from "@mantine/core";
import classes from "./select-card.module.css";

export type SelectCardStylesNames = "root";
export type SelectCardCssVariables = {
  root: "--sc-selected-color" | "--sc-selected-bg" | "--sc-selected-bdc";
};

export interface SelectCardProps
  extends BoxProps,
    StylesApiProps<SelectCardFactory>,
    Omit<ElementProps<"button">, "onClick"> {
  selected?: boolean;
  onSelect: () => void;
  color?: string;
}

export type SelectCardFactory = Factory<{
  props: SelectCardProps;
  ref: HTMLButtonElement;
  stylesNames: SelectCardStylesNames;
  vars: SelectCardCssVariables;
}>;

const defaultProps: Partial<SelectCardProps> = {};

const varsResolver = createVarsResolver<SelectCardFactory>(
  (theme, { color }) => {
    // Create light color variant
    const colors = theme.variantColorResolver({
      color: color ?? theme.primaryColor,
      theme,
      variant: "light",
      autoContrast: true,
    });

    return {
      root: {
        "--sc-selected-color": colors.color,
        "--sc-selected-bg": colors.background,
        "--sc-selected-bdc": colors.color,
      },
    };
  }
);

export const SelectCard = factory<SelectCardFactory>((_props, ref) => {
  const props = useProps("SelectCard", defaultProps, _props);
  const {
    classNames,
    className,
    style,
    styles,
    unstyled,
    vars,
    selected,
    onSelect,
    ...others
  } = props;

  const getStyles = useStyles<SelectCardFactory>({
    name: "SelectCard",
    classes,
    props,
    className,
    style,
    classNames,
    styles,
    unstyled,
    vars,
    varsResolver,
  });

  return (
    <Paper
      component="button"
      ref={ref}
      data-selected={selected}
      shadow={!selected ? "sm" : undefined}
      {...getStyles("root")}
      onClick={onSelect}
      {...others}
    />
  );
});

SelectCard.classes = classes;
SelectCard.displayName = "SelectCard";
