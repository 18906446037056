import { useBreakpoint } from "..";

const HEIGHTS_MAP = {
  base: 90,
  xs: 60,
  md: 75,
};

export const useHeaderHeight = () => {
  const isBelowXs = useBreakpoint("xs");
  const isBelowMd = useBreakpoint("md");

  if (isBelowXs) return HEIGHTS_MAP.xs;
  if (isBelowMd) return HEIGHTS_MAP.md;
  return HEIGHTS_MAP.base;
};
