export const parseTableDataCell = (
  cell: number | string,
  isPercentage: boolean
) => {
  if (typeof cell === "number") {
    return `${cell.toLocaleString()}${isPercentage ? " %" : ""}`;
  }

  if (cell.includes("§")) {
    return `${Number(cell.replace("§", "")).toLocaleString()}${
      isPercentage ? " %" : ""
    }`;
  }

  return `${Number(cell).toLocaleString()}${isPercentage ? " %" : ""}`;
};
