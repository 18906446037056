export enum QueryKeys {
  AgeGroups = "ageGroups",
  Areas = "areas",
  CaseCounts = "caseCounts",
  CaseCountsStratified = "caseCountsStratified",
  CaseCountsUpdateHistory = "caseCountsUpdateHistory",
  CaseCountsUpdateHistoryLatest = "caseCountsUpdateHistoryLatest",
  Diseases = "diseases",
  DiseaseLatestYear = "diseaseLatestYear",
  DiseaseYears = "diseaseYears",
  Genders = "genders",
  Geometries = "geometries",
  Races = "races",
  SDOH = "sdoh",
  Search = "search",
  Sexes = "sexes",
  Transmissions = "transmissions",
}
