import {
  Switch,
  useComputedColorScheme,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { IconMoon, IconSun } from "@tabler/icons-react";
import { useTranslation } from "next-i18next";

export const ColorSchemeSwitch = () => {
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme("light");
  const { toggleColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const { t } = useTranslation(["common"]);
  const handleToggleClick = () => {
    toggleColorScheme();
  };
  const colorSchemeSwitch = t("color-scheme-switch.switch");
  const sunIcon = t("color-scheme-switch.sun-icon");
  const moonIcon = t("color-scheme-switch.moon-icon");

  return (
    <Switch
      size="lg"
      checked={colorScheme === "dark"}
      onLabel={<IconSun size="1rem" stroke={2.5} aria-label={sunIcon} />}
      offLabel={
        <IconMoon
          size="1rem"
          stroke={2.5}
          color={theme.colors.gray[8]}
          aria-label={moonIcon}
        />
      }
      onClick={handleToggleClick}
      color="dark"
      aria-label={colorSchemeSwitch}
    />
  );
};
