import { DefaultMantineColor, MantineColorsTuple } from "@mantine/core";

export const colors: Record<DefaultMantineColor, MantineColorsTuple> = {
  dark: [
    "#FFFFFF",
    "#A6A7AB",
    "#909296",
    "#5C5F66",
    "#373A40",
    "#2C2E33",
    "#25262B",
    "#2E2E2E",
    "#141517",
    "#101113",
  ],
  gray: [
    "#F9F9F9",
    "#F0F0F0",
    "#E0E0E0",
    "#C7C7C7",
    "#949494",
    "#7A7A7A",
    "#616161",
    "#474747",
    "#2E2E2E",
    "#1B1B1B",
  ],
  red: [
    "#FDF3F3",
    "#F5C9C4",
    "#F5AEA6",
    "#F29085",
    "#ED5847",
    "#ED3B26",
    "#BE3226",
    "#A62C21",
    "#80160D",
    "#4D0D08",
  ],
  pink: [
    "#FFF0F6",
    "#FFDEEB",
    "#FCC2D7",
    "#FAA2C1",
    "#F783AC",
    "#F06595",
    "#E64980",
    "#D6336C",
    "#C2255C",
    "#A61E4D",
  ],
  grape: [
    "#F5E1FF",
    "#EEBEFA",
    "#E599F7",
    "#CC5DE8",
    "#BE66CC",
    "#C677C9",
    "#B266B5",
    "#9A4F9D",
    "#843887",
    "#652667",
  ],
  violet: [
    "#F3F0FF",
    "#E5DBFF",
    "#D0BFFF",
    "#B197FC",
    "#9775FA",
    "#845EF7",
    "#7950F2",
    "#6A44DB",
    "#5737B8",
    "#4A3099",
  ],
  indigo: [
    "#EDF2FF",
    "#DBE4FF",
    "#BAC8FF",
    "#91A7FF",
    "#748FFC",
    "#5C7CFA",
    "#4C6EF5",
    "#3F5FE0",
    "#3755CC",
    "#2A3D99",
  ],
  blue: [
    "#EBFCFF",
    "#CEF7FF",
    "#9ED4FC",
    "#74C0FC",
    "#00BDE3",
    "#0A9CD1",
    "#1485C7",
    "#1A6EB2",
    "#1C4B84",
    "#112E51",
  ],
  cyan: [
    "#E3FAFC",
    "#C5F6FA",
    "#99E9F2",
    "#5AD1E0",
    "#37BBCC",
    "#1FA8BD",
    "#0C9BA6",
    "#008F94",
    "#00778A",
    "#00363F",
  ],
  teal: [
    "#E6FCF5",
    "#C3FAE8",
    "#9DFFF3",
    "#6DF2E2",
    "#17E5CC",
    "#19D1BB",
    "#1DC2AE",
    "#00A399",
    "#008080",
    "#004A4D",
  ],
  green: [
    "#F5FFF6",
    "#D3F9D6",
    "#C3F3C7",
    "#8CE994",
    "#62D96B",
    "#47CC52",
    "#39BF45",
    "#20A62C",
    "#0A6612",
    "#053309",
  ],
  lime: [
    "#EEFAE1",
    "#E1FAC8",
    "#D0F5AB",
    "#A1E55C",
    "#7ACC29",
    "#73BF26",
    "#589917",
    "#498013",
    "#3F730B",
    "#315909",
  ],
  yellow: [
    "#FFF9F0",
    "#FBF5D5",
    "#FFEF99",
    "#FFE066",
    "#FFC25B",
    "#F2BC3D",
    "#FCB81F",
    "#F69F1D",
    "#FF8000",
    "#CC6600",
  ],
  orange: [
    "#FFF0E6",
    "#FFE1CC",
    "#FFB580",
    "#FFA04D",
    "#FF8833",
    "#FF791A",
    "#E5660B",
    "#CC5B0A",
    "#B24209",
    "#802506",
  ],
};
