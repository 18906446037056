import { Box, Drawer, Flex, Text } from "@mantine/core";
import { useUiStore } from "stores";
import logoLight from "@public/images/ahead-logo-light.svg";
import logoDark from "@public/images/ahead-logo-dark.svg";
import { ColorSchemeSwitch } from "@components/layout/color-scheme-switch";
import { ColorSchemeImage, Link } from "@components/ui";
import { useTranslation } from "next-i18next";
import { Menu } from "@components/layout/side-nav/menu";
import { NavLinks } from "@components/layout/header";
import classes from "./side-nav.module.css";

export const SideNav = () => {
  const { t } = useTranslation(["common"]);
  const links = t("nav.links", { returnObjects: true }) as NavLinks[];

  const isSideNavOpen = useUiStore((state) => state.isSideNavOpen);
  const toggleSideNav = useUiStore((state) => state.toggleSideNav);

  const close = t("close");

  return (
    <Drawer
      opened={isSideNavOpen}
      onClose={toggleSideNav}
      title={
        <ColorSchemeImage
          lightSrc={logoLight}
          darkSrc={logoDark}
          alt="AHEAD logo"
          height={70}
          width={158}
          unoptimized
        />
      }
      padding="xl"
      classNames={{
        close: classes.close,
        header: classes.header,
        content: classes.content,
        body: classes.body,
      }}
      zIndex={500}
      closeButtonProps={{ "aria-label": close }}
    >
      <ul>
        {Array.isArray(links) &&
          links.map(({ name, nested }) => (
            <li key={name}>
              <Menu>
                <Menu.Target>
                  <Text size="xl">{name}</Text>
                </Menu.Target>
                {nested.map(({ name: nestedName, url: nestedUrl }) => (
                  <Menu.Item key={nestedName}>
                    <Link href={nestedUrl} onClick={toggleSideNav}>
                      {nestedName}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu>
            </li>
          ))}
      </ul>
      <Box p="md" className={classes.preferences}>
        <Flex justify="space-between">
          <Text>{t("nav.buttons.display-preferences")}</Text>
          <ColorSchemeSwitch />
        </Flex>
      </Box>
    </Drawer>
  );
};
