import { subscriptionApi } from "./axios";

export const postSubscribeEmail = async (
  email: string,
  recaptchaResponse: string | null | undefined
) =>
  subscriptionApi.post<string>("/", {
    notificationChannelId: 0,
    contactId: email,
    response: recaptchaResponse,
  });
