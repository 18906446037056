import {
  ActionIcon,
  Anchor,
  Button,
  Checkbox,
  Combobox,
  Container,
  Divider,
  Input,
  List,
  Loader,
  Modal,
  MultiSelect,
  Pagination,
  Pill,
  Select,
  Switch,
  Tooltip,
  VariantColorsResolver,
  createTheme,
  defaultVariantColorsResolver,
  rem,
} from "@mantine/core";
import cx from "clsx";
import { Poppins, Roboto } from "next/font/google";
import { colors } from "./colors";

import classes from "./app.module.css";

const roboto = Roboto({
  weight: ["400", "700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  display: "swap",
});

const poppins = Poppins({
  weight: ["400", "700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  display: "swap",
});

const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);
  return defaultResolvedColors;
};

const theme = createTheme({
  variantColorResolver,
  colors,
  breakpoints: { xl: "100em" },
  primaryColor: "blue",
  primaryShade: { light: 7, dark: 4 },
  cursorType: "pointer",
  defaultRadius: "sm",
  fontFamily: roboto.style.fontFamily,
  headings: {
    fontFamily: poppins.style.fontFamily,
  },
  components: {
    // todo: work on global action icon styles
    ActionIcon: ActionIcon.extend({
      defaultProps: {
        variant: "filled",
        color: "blue",
        size: "lg",
      },
      classNames: (_theme, { color, variant, disabled }) => ({
        root: cx({
          [classes.actionIconDefault]:
            !disabled && (!color || color === "blue") && variant === "filled",
          [classes.actionIconGrayFilled]:
            color === "gray" && variant === "filled",
        }),
      }),
    }),
    Anchor: Anchor.extend({
      styles: {
        root: { textDecoration: "underline" },
      },
    }),
    // todo: work on global button styles
    Button: Button.extend({
      defaultProps: {
        variant: "filled",
      },
      classNames: (_, { color, variant, disabled }) => ({
        root: cx({
          [classes.buttonDefault]:
            !disabled && (!color || color === "blue") && variant === "filled",
          [classes.buttonFilledAndGray]:
            variant === "filled" && color === "gray",
        }),
      }),
    }),
    Checkbox: Checkbox.extend({
      classNames: {
        root: classes.checkbox,
        input: classes.checkboxInput,
        icon: classes.checkboxIcon,
      },
    }),
    Container: Container.extend({
      defaultProps: {
        size: "xl",
      },
      vars: (_, { size, fluid }) => ({
        // only change container size to rem(1600) if size is XL
        root: {
          ...(size === "xl" && !fluid && { "--container-size": rem(1600) }),
        },
      }),
    }),
    Combobox: Combobox.extend({
      classNames: {
        dropdown: classes.comboboxDropdown,
      },
    }),
    List: List.extend({
      classNames: {
        itemWrapper: classes.listItemWrapper,
      },
    }),
    Divider: Divider.extend({
      classNames: {
        root: classes.divider,
      },
    }),
    Header: {
      classNames: {
        root: classes.header,
      },
    },
    Loader: Loader.extend({
      classNames: {
        root: classes.loader,
      },
    }),
    Input: Input.extend({
      classNames: {
        input: classes.input,
        section: classes.inputSection,
      },
    }),
    Modal: Modal.extend({
      defaultProps: {
        centered: true,
        padding: "lg",
      },
      classNames: {
        header: classes.modalHeader,
        body: classes.modalBody,
        close: classes.modalClose,
        overlay: classes.modalOverlay,
        inner: classes.modalInner,
      },
    }),
    MultiSelect: MultiSelect.extend({
      classNames: {
        root: classes.multiSelect,
        dropdown: classes.multiSelectDropdown,
        input: classes.multiSelectInput,
        label: classes.multiSelectLabel,
        inputField: classes.multiSelectInputField,
      },
    }),
    Pagination: Pagination.extend({
      classNames: {
        control: classes.paginationControl,
      },
    }),
    Pill: Pill.extend({
      classNames: {
        root: classes.pillRoot,
      },
    }),
    Select: Select.extend({
      classNames: {
        dropdown: classes.comboboxDropdown,
      },
    }),
    Switch: Switch.extend({
      classNames: {
        thumb: classes.switchThumb,
        track: classes.switchTrack,
      },
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        color: "blue",
      },
      classNames: {
        tooltip: classes.tooltip,
      },
    }),
  },
});

export default theme;
