import { RefObject, useCallback } from "react";
import { useTranslation } from "next-i18next";
import { toPng } from "html-to-image";
import { notifications } from "@mantine/notifications";
import { useComputedColorScheme, useMantineTheme } from "@mantine/core";

export const useToPng = (
  pngRef: RefObject<HTMLDivElement> | null,
  filter?: (node: HTMLElement) => boolean
) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme("light");

  // Use this function to remove elements from image
  // todo: Remove when not needed and filters are implemented
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const oldfilter = (node: HTMLElement) => {
    const conditions: boolean[] = [];
    conditions.push(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      node.getAttribute &&
        node.getAttribute("data-testid") === "share-container"
    );

    return !conditions.some((condition) => condition);
  };

  return useCallback(
    () =>
      pngRef?.current &&
      toPng(pngRef.current, {
        cacheBust: true,
        filter,
        backgroundColor:
          colorScheme === "light" ? theme.white : theme.colors.gray[7],
      })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "data-view.png";
          link.href = dataUrl;
          link.click();
          notifications.show({
            title: t("notifications:success"),
            color: "green",
            message: t("notifications:png.success"),
          });
        })
        .catch(() => {
          notifications.show({
            title: t("notifications:error"),
            color: "red",
            message: t("notifications:png.error"),
          });
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, pngRef, t, colorScheme]
  );
};
