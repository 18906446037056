import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "enums";
import { getCaseCountsStratified } from "../axios";
import type { CaseCounts, Filters } from "../case-counts.type";

// This type "copies" the options type from the useQuery we would call with T
// ? tanstack provides a usequeryoptions type but it did not work as intended at first
// * it's important that the query key types stay in sync
export type CaseCountsQueryOptions<T> = Parameters<
  typeof useQuery<CaseCounts, unknown, T, (QueryKeys | Filters)[]>
>[2];

export const useCaseCountsStratified = <T = CaseCounts>(
  filters: Partial<Filters>,
  options?: CaseCountsQueryOptions<T>
) =>
  useQuery(
    [QueryKeys.CaseCountsStratified, filters],
    () => getCaseCountsStratified(filters),
    options
  );
