import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "enums";
import { filterDefaultValues } from "utils";
import { areasApi } from "../axios";
import { Area } from "../area.type";

export const getArea = async (state: string, county: string | undefined) =>
  (await areasApi.get<Area>(state.concat(county ? `/${county}` : ""))).data;

export const getAreas = async () =>
  (await areasApi.get<Area[]>("/", { params: { pageLimit: 10000 } })).data;

export const useAreas = () =>
  useQuery([QueryKeys.Areas], getAreas, {
    select: (data) => filterDefaultValues(data) as Area[],
  });
