import { FC, ReactElement, ReactNode } from "react";

const flattenChildren = (children: ReactNode[]): any[] => {
  let flat: ReactNode[] = [];

  children.forEach((child) => {
    if (Array.isArray(child)) {
      flat = flat.concat(flattenChildren(child));
    } else {
      flat.push(child);
    }
  });

  return flat;
};

export const useComponents = (children: ReactNode, component: FC<any>) => {
  const components: ReactElement[] = [];
  const target = component.displayName;
  const elements = flattenChildren(
    Array.isArray(children) ? children : [children]
  );

  elements.forEach((element) => {
    if (element?.type?.displayName === target) {
      components.push(element);
    }
  });

  return components;
};
