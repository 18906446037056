import { useLocalStorage } from "@mantine/hooks";
import { useEffect, useState } from "react";

export const useFirstTime = (page: string) => {
  const [isHydrated, setIsHydrated] = useState(false);
  const [isFirstTime, setIsFirstTime] = useLocalStorage<boolean>({
    key: `is-first-time-${page}`,
    defaultValue: true,
    getInitialValueInEffect: true,
  });

  useEffect(() => {
    setIsHydrated(true);
  }, [setIsFirstTime]);

  return { isFirstTime, isHydrated, setIsFirstTime, setIsHydrated };
};
