import { Footer } from "@components/layout/footer/footer";
import { Header } from "@components/layout/header";
import { SideNav } from "@components/layout/side-nav";
import { Tophat } from "@icf-corp/hivgov-common-ui/tophat";
import { LocatorFooterBar } from "@icf-corp/hivgov-common-ui/locator-footer-bar";
import { Box, Flex } from "@mantine/core";

export interface LayoutProps extends React.PropsWithChildren {}

export const Layout: React.FC<LayoutProps> = ({ children }) => (
  <>
    <Flex w="100%" maw="100%" style={{ overflowX: "clip" }} direction="column">
      <Box maw="100%" style={{ overflowX: "clip" }}>
        <Tophat currentSite="AHEAD" />
      </Box>
      <Header />
      <Box>
        <Box data-testid="content">{children}</Box>
        <Box mt="3rem">
          <LocatorFooterBar />
        </Box>
        <Footer />
      </Box>
    </Flex>
    <SideNav />
  </>
);
