import {
  CSSVariablesResolver,
  MantinePrimaryShade,
  darken,
  lighten,
} from "@mantine/core";

const resolver: CSSVariablesResolver = (theme) => ({
  variables: {},
  light: {
    "--mantine-color-body": theme.colors.gray[0],
    "--mantine-color-text": theme.colors.gray[9],
    "--mantine-button-hover-bg-color": theme.colors.blue[8],
    "--mantine-anchor-hover-color": darken(theme.colors.blue[7], 0.3),
  },
  dark: {
    "--mantine-color-body": theme.colors.gray[8],
    "--mantine-color-text": theme.white,
    "--mantine-button-hover-bg-color": darken(theme.colors.blue[4], 0.1),
    "--mantine-anchor-hover-color": lighten(theme.colors.blue[4], 0.3),
    // * This assumes primaryShade is the light/dark object
    [`--mantine-color-${theme.primaryColor}-outline`]:
      theme.colors[theme.primaryColor][
        (theme.primaryShade as MantinePrimaryShade).dark
      ],
  },
});

export default resolver;
