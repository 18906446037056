import { Diseases } from "@enums/diseases";
import diagnosesIconDark from "@public/icons/diagnoses-icon-dark.svg";
import diagnosesIconLight from "@public/icons/diagnoses-icon-light.svg";
import incidenceIconDark from "@public/icons/incidence-icon-dark.svg";
import incidenceIconLight from "@public/icons/incidence-icon-light.svg";
import knowledgeOfStatusIconDark from "@public/icons/knowledge-of-status-icon-dark.svg";
import knowledgeOfStatusIconLight from "@public/icons/knowledge-of-status-icon-light.svg";
import linkageToCareIconDark from "@public/icons/linkage-to-care-icon-dark.svg";
import linkageToCareIconLight from "@public/icons/linkage-to-care-icon-light.svg";
import prepCoverageIconDark from "@public/icons/prep-coverage-icon-dark.svg";
import prepCoverageIconLight from "@public/icons/prep-coverage-icon-light.svg";
import viralSuppressionIconDark from "@public/icons/viral-suppression-icon-dark.svg";
import viralSuppressionIconLight from "@public/icons/viral-suppression-icon-light.svg";

export const indicatorMap = {
  [Diseases.Incidence]: {
    src: { light: incidenceIconLight, dark: incidenceIconDark },
    color: "grape.7",
    order: 0,
    slug: "incidence",
  },
  [Diseases.KnowledgeOfStatus]: {
    src: {
      light: knowledgeOfStatusIconLight,
      dark: knowledgeOfStatusIconDark,
    },
    color: "yellow.7",
    order: 1,
    slug: "knowledge",
  },
  [Diseases.Diagnoses]: {
    src: { light: diagnosesIconLight, dark: diagnosesIconDark },
    color: "teal.4",
    order: 2,
    slug: "diagnoses",
  },
  [Diseases.LinkageToCare]: {
    src: { light: linkageToCareIconLight, dark: linkageToCareIconDark },
    color: "blue.7",
    order: 3,
    slug: "linkage",
  },
  [Diseases.ViralSuppression]: {
    src: {
      light: viralSuppressionIconLight,
      dark: viralSuppressionIconDark,
    },
    color: "red.5",
    order: 4,
    slug: "suppression",
  },
  [Diseases.PrEPCoverage]: {
    src: {
      light: prepCoverageIconLight,
      dark: prepCoverageIconDark,
    },
    color: "cyan.8",
    order: 5,
    slug: "prep",
  },
};
